import React, { lazy } from "react";

const Dashboard = lazy(() => import('views/dashboard'));
const Orders = lazy(() => import('views/order-management/orders'));
const OrderDetail = lazy(() => import('views/order-management/orders/Detail.js'));
const Products = lazy(() => import('views/products'));
const AddProduct = lazy(() => import('views/products/AddProduct'));
const UpdateProduct = lazy(() => import('views/products/UpdateProduct'));
const ActivateMembership = lazy(() => import('views/membership/ActivateMembership'));
const UpdateMembership = lazy(() => import('views/membership/UpdateMembership'));
const Profile = lazy(() => import('views/profile'));
const Vouchers = lazy(() => import('views/vouchers'));
const AddVoucher = lazy(() => import('views/vouchers/AddVoucher'));
const UpdateVoucher = lazy(() => import('views/vouchers/UpdateVoucher'));
const SaleManagement = lazy(() => import('views/sale-management'));
const Reviews = lazy(() => import('views/review-management'));
const SaleProduct = lazy(() => import('views/sale-management/SaleProduct'));
const Transactions = lazy(() => import('views/transactions'));
const Chat = lazy(() => import('views/chat'));
const FulfillmentProducts = lazy(() => import('views/fulfillment/products'));
const AddFulfillmentProduct = lazy(() => import('views/fulfillment/products/AddProduct'));
const FulfillmentProductDetail = lazy(() => import('views/fulfillment/products/ProductDetail'));
const InvoiceManagement = lazy(() => import('views/invoice-management'));
const ViewInvoice = lazy(() => import('views/invoice-management/ViewInvoice'));
const FulfillmentOrders = lazy(() => import('views/fulfillment/orders'));
const FulfillmentOrderDetail = lazy(() => import('views/fulfillment/orders/Detail'));

const DashboardRoutes = [
  {
    path: "/",
    component: <Dashboard />,
  },
  {
    path: "/product-management/products",
    component: <Products />,
  },
  {
    path: "/product-management/add-product",
    component: <AddProduct />,
  },
  {
    path: "/product-management/edit-product",
    component: <UpdateProduct />,
  },
  {
    path: "/order-management/orders",
    component: <Orders />,
  },
  {
    path: "/order-management/order-detail/:id",
    component: <OrderDetail />,
  },
  {
    path: "/review-management",
    component: <Reviews />,
  },
  {
    path: "/vouchers",
    component: <Vouchers />,
  },
  {
    path: "/vouchers/add-voucher",
    component: <AddVoucher />,
  },
  {
    path: "/vouchers/edit-voucher",
    component: <UpdateVoucher />,
  },
  {
    path: "/order-management/order-detail/:id",
    component: <OrderDetail />,
  },
  {
    path: "/transactions",
    component: <Transactions />,
  },
  {
    path: "/membership-plan/activate-membership",
    component: <ActivateMembership />,
  },
  {
    path: "/membership-plan/update-membership",
    component: <UpdateMembership />,
  },
  {
    path: "/sale-management",
    component: <SaleManagement />,
  },
  {
    path: "/sale-management/sale-product/:id",
    component: <SaleProduct />,
  },
  {
    path: "/profile",
    component: <Profile />,
  },
  {
    path: "/chat",
    component: <Chat />,
  },
  {
    path: "/fulfillment/products",
    component: <FulfillmentProducts />,
  },
  {
    path: "/fulfillment/products/add-product",
    component: <AddFulfillmentProduct />,
  },
  {
    path: "/fulfillment/product-detail/:id",
    component: <FulfillmentProductDetail />,
  },
  {
    path: "/fulfillment/orders",
    component: <FulfillmentOrders />,
  },
  {
    path: "/fulfillment/orders/detail/:id",
    component: <FulfillmentOrderDetail />,
  },
  {
    path: "/invoice-management",
    component: <InvoiceManagement />,
  },
  {
    path: "/invoice-management/view-invoice/:id",
    component: <ViewInvoice />,
  },
]

export default DashboardRoutes
